body {
  margin: 0;
  padding: 0;
  background: rgb(41, 41, 41);
  color: white;
  font-family: 'Courier New', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


a{
  text-decoration: none;
  font-family: monospace;
  background: rgb(114, 56, 2);
    text-align: center;
    color: white;
    border-radius: 0.3em;
    margin: 0.2em;
    padding: 0.2em;
    width: 3.3em;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid rgb(143, 75, 34);
}

a:hover{
  transition: background 400ms linear;
  background: rgb(79, 35, 8);
}