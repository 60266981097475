.App{
  padding: 10px;
  font-size: 11px;
  cursor: text;
}

.App-header{
  color: #fff;
  line-height: 15px;
  margin-bottom: 5%;
}

.App-header span{
  display: block;
  margin: 5px 0;
}

.App-header span b{
  margin: 2px;
  padding: 3px;
  font-family: monospace;
  background: #eee;
    text-align: center;
    color: #222222;
    border-radius: 0.3em;
    margin: 0.2em;
    padding: 0.2em;
    width: 3.3em;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #cccccc;
    box-shadow: 0 0.2em 0 0.05em #bababa;
    border-bottom-color: #aaa;
}

.Terminal{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}


.Terminal .App-repository{
  /* float: left; */
  /* align-self: center; */
  width: max-content;
  word-wrap: normal;
  /* margin-top: 1px; */
}

.Terminal .App-command{
  flex-grow: 2;
}

.Terminal .content{
  margin: auto;
}
.Terminal .content li{
  list-style: none;
  line-height: 2;
}

input[type="text" i]{
  padding: 0;
}
.App-command{
  position: relative;
}
.App-command input[type="text" i]{
  letter-spacing: 0.5px;
  /* line-height: 12px; */
  padding-bottom: 2px;
  font: inherit;
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
  color: #fff;
  text-overflow: ellipsis;
  caret-color: transparent !important;
}

.App-command > span{
  position: absolute;
  letter-spacing: 0.5px;
  left: 0;
  top: 0;
  height: 12px;
}


.custom-caret{
  height: 12px;
  background-color: #eee;
  animation: blink 1200ms linear infinite;
  width: 5px;
  position: relative;
  right: 0;
  margin: 0;
  display: inline-block;
}

.History-command{
  letter-spacing: 0.5px;
  padding-bottom: 2px;
  font: inherit;
  color: #fff;
}

.App-repository{
  padding-right: 5px;
  font-weight: 900;
  color: rgb(54, 194, 36);
}

.mini_icon{
  height: auto;
  width: 22px;
  display: inline-block;
  vertical-align: middle;
  pointer-events: none;
  margin-right: 5px;
}

.medium_icon{
  height: 50px;
  width: auto;
  display: inline-block;
  vertical-align: middle;
  pointer-events: none;
  margin: auto 10px;
}

.App-logo {
  height: 25vmin;
  margin: auto;
  display: block;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes blink{
  0% {    
    background-color: #ffffff;  
  }  
  49% {    
    background-color: #ffffff;  
  }  
  60% {    
    background-color: transparent;  
  }  
  99% {    
    background-color: transparent;  
  }  100% {    
    background-color: #ffffff;  
  }
}


@media screen and (max-width: 650px){
  .content span{
    display: none !important;
  }
}