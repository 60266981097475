
.modal_warning{
    z-index: 9999;
    position: absolute;
    top: 50%;left: 50%;
    transform: translate(-50%, -50%);
    max-width: 600px;
    text-align: center;
    min-width: 300px;
    height: auto;
    padding: 20px;
    line-height: 2;
    background-color: white;
    color: #111;
    border-radius: 10px;
}


.Window-topbar{
    display: block;
    height: 20px;
    width: 100%;
    margin: 0;
    padding: 4px 0;
    font-size: 11px;
    text-align: center;
    
    overflow: hidden;
    box-sizing: border-box;
    background: rgb(10, 10, 10);
}

.Window-grid{
    display: grid;
    position: relative;
    
    margin: auto 0 0 auto;
    padding: 0;
    height: calc(100% - 20px - 14px);
    width: 100%;
    box-sizing: border-box;
    grid-template-columns: repeat(auto-fill, minmax(73px,75px));
    grid-template-rows: repeat(auto-fill, minmax(73px,75px));
    gap: 10px;
    grid-auto-flow: column;
    place-items: center;
    place-content: end start;
}




.Window-icon{
    position: relative;
    margin: 2px;
    padding: 5px;
    min-height: 73px;
    min-width: 73px;
    max-height: 75px;
    max-width: 75px;
    align-items: flex-end;
    
    display: flex;
    flex-direction: column;
    
    background-color: transparent;
    transition: background-color 100ms;

    border-radius: 2px;
}

.Window-icon:hover{
    background-color: rgba(255, 255, 255, 0.3);
}

.Window-icon > *{
    user-select: none;
}

.Window-icon img{
    height: 64px;
    width: 64px;
    margin: auto;
    padding: 0;
    display: block;
}

.Window-icon span{
    cursor: default;
    font-size: 9px;
    text-align: center;
    width: 100%;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}



.Window-screen{
    min-height: 300px;
    min-width: 300px;
    z-index: 1;
    background-color: rgb(38, 38, 38);
    position: absolute;   
    border-radius: 5px;
    border: 1px solid #111;
    overflow: hidden;
    resize: both;
    outline: none;
}

.Window-screen .Window-toolbar{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    -webkit-user-select: none;
    user-select: none;
    padding: 10px;
    font-size: 12px;
    align-items: baseline;
    max-height: min-content;
    background-color: rgb(41, 41, 41);

}

.Window-screen:focus{
    background-color: rgb(22, 22, 22);
}
.Window-screen:focus .Window-toolbar{
    background-color: rgb(32, 33, 36);
}

.Window-screen .Window-toolbar > div:first-child{
    flex-grow: 3;
    text-align: center;
}
.Window-screen .Window-toolbar > div:last-child{
    /* flex-grow: 1; */
    text-align: right;
}

.Window-screen .Window-toolbar span{
    display: inline-flex;
    vertical-align: bottom;
    text-align: center;
    height: 15px;
    border-radius: 50%;
    width: 15px;
    padding: 2px;
    margin: auto 2px 0;
    font-size: 10px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
}
.Window-screen .Window-toolbar span:hover{
    background-color: rgb(80, 80, 80);
}
.Window-screen .Window-toolbar span:last-child{
    background-color: red;
}

.Window-body{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}